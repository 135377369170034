:root {
  --backgroundColor: #FFFFFF;
  --textColor: #000000;
  --linkColor: #000000;
  --linkHoverColor: #000000;
}
a.load {
  --linkColor: #000000;
  --linkHoverColor: #FFFFFF;
}
.section--moodvideo,
.section--black {
  --backgroundColor: #000000;
  --textColor: #FFFFFF;
  --linkColor: #FFFFFF;
  --linkHoverColor: #FFFFFF;
}
.section--moodvideo a.load,
.section--black a.load {
  --linkColor: #FFFFFF;
  --linkHoverColor: #000000;
}
.section--grey {
  --backgroundColor: #F0F0F0;
}
.unit--boxLinked {
  --linkColor: #000000;
  --linkHoverColor: #000000;
}
.unit--boxLinked.unit--hasBackground {
  --linkColor: #FFFFFF;
  --linkHoverColor: #FFFFFF;
}
.unit--unitGameDay {
  --backgroundColor: #000000;
  --textColor: #FFFFFF;
  --linkColor: #FFFFFF;
  --linkHoverColor: #FFFFFF;
}
.unit--unitGame {
  --backgroundColor: #F0F0F0;
}
:root {
  --spaceArea: calc(100px - var(--spacePart) - var(--spaceUnit));
}
@media (max-width: 1279px) {
  :root {
    --spaceArea: calc(100px - var(--spacePart) - var(--spaceUnit));
  }
}
@media (max-width: 767px) {
  :root {
    --spaceArea: calc(80px - var(--spacePart) - var(--spaceUnit));
  }
}
:root .section--three {
  --spaceArea: 0;
}
:root {
  --deskWidth: 920px;
  --deskSpace: 21px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
:root .section--one {
  --deskWidth: 732px;
}
:root .section--four {
  --deskWidth: 1108px;
}
@media (min-width: 768px) {
  :root {
    --deskSpace: 177px;
  }
  :root .navbar,
  :root .section--three,
  :root .section--four,
  :root .section--five,
  :root .section--footer {
    --deskSpace: 62px;
    --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
  }
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1279px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root .section--three {
  --spacePart: 0;
}
:root {
  --spaceUnit: 10px;
}
@media (max-width: 1279px) {
  :root {
    --spaceUnit: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 10px;
  }
}
:root .section--three {
  --spaceUnit: 0;
}
:root .unit--imageLeftRight {
  --spaceUnit: 30px;
}
:root {
  --spaceTotal: 20px;
}
@media (max-width: 1279px) {
  :root {
    --spaceTotal: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 20px;
  }
}
:root .section--four {
  --spaceTotal: 0;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #E92B22;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #dc1f16;
}
.button:active {
  background-color: #ad1811;
}
#tickets .meta {
  width: 190px;
  padding-left: 0;
  padding-right: 50px;
  font-size: 30px;
  line-height: 1.2;
}
#tickets .meta:before {
  width: 30px;
  background-size: 240px 30px;
}
#tickets .meta:hover:before,
#tickets .meta:focus:before {
  width: 190px;
}
.section--footer .footerArea .footerUnit {
  display: flex;
  justify-content: center;
}
.section--footer .footerArea .footerUnit .footerPart {
  width: 33.33333333%;
}
.layout1 h1 {
  font-size: 140px;
  line-height: 0.92857143;
}
.layout1 h1:before {
  top: 65px;
  height: 135px;
}
.section--moodvideo .loud {
  font-size: 30px;
  line-height: 1.2;
}
.section--one .area {
  width: calc(100% + 20px);
  margin-left: -10px;
}
.section--one .area .unit .part {
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}
.section--one .area .unit--article.slim {
  width: 50%;
}
.section--one .area .unit--article .part.tiny {
  width: calc(50% - 20px);
}
.section--two .area,
.section--five .area {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin-left: -10px;
}
.section--two .area .unit .part,
.section--five .area .unit .part {
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}
.section--two .area .unit--article.slim,
.section--five .area .unit--article.slim {
  width: 50%;
}
.section--two .area .unit--article .part.tiny,
.section--five .area .unit--article .part.tiny {
  width: calc(50% - 20px);
}
.section--two .area .unit--form,
.section--five .area .unit--form {
  width: 66.66666667%;
}
.section--two .area .unit--boxLinked,
.section--five .area .unit--boxLinked {
  width: calc(100% / 2 - 20px);
  margin-left: 10px;
  margin-right: 10px;
  padding: 50px;
}
.section--two .area .unit--boxLinked .part,
.section--five .area .unit--boxLinked .part {
  width: 100%;
  margin-left: 0;
}
.section--two .area .unit.unit--unitGameDay,
.section--five .area .unit.unit--unitGameDay {
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}
.section--two .area .unit.unit--unitGame,
.section--five .area .unit.unit--unitGame {
  width: calc(100% / 2 - 20px);
  margin-left: 10px;
  margin-right: 10px;
}
.section--four .area {
  width: calc(100% + 20px);
  margin-left: -10px;
}
.section--four .area .unit.unit--unitSponsors .part {
  width: calc(100% / 4 - 20px);
}
.section--four .area .unit.unit--imageLeftRight,
.section--four .area .unit.unit--mapLeftRight {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: min(49.0234375vw, 502px);
}
.section--four .area .unit.unit--imageLeftRight .unit__background,
.section--four .area .unit.unit--mapLeftRight .unit__background,
.section--four .area .unit.unit--imageLeftRight .part.cb-googlemaps,
.section--four .area .unit.unit--mapLeftRight .part.cb-googlemaps {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateY(-50%);
  width: 49.0234375vw;
  height: 100%;
}
.section--four .area .unit.unit--imageLeftRight .unit__background img,
.section--four .area .unit.unit--mapLeftRight .unit__background img,
.section--four .area .unit.unit--imageLeftRight .part.cb-googlemaps img,
.section--four .area .unit.unit--mapLeftRight .part.cb-googlemaps img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section--four .area .unit.unit--imageLeftRight .unit__background .cb-googlemapscontainer,
.section--four .area .unit.unit--mapLeftRight .unit__background .cb-googlemapscontainer,
.section--four .area .unit.unit--imageLeftRight .part.cb-googlemaps .cb-googlemapscontainer,
.section--four .area .unit.unit--mapLeftRight .part.cb-googlemaps .cb-googlemapscontainer {
  height: 100%!important;
}
.section--four .area .unit.unit--imageLeftRight .unit__background .cb-googlemapscontainer > div,
.section--four .area .unit.unit--mapLeftRight .unit__background .cb-googlemapscontainer > div,
.section--four .area .unit.unit--imageLeftRight .part.cb-googlemaps .cb-googlemapscontainer > div,
.section--four .area .unit.unit--mapLeftRight .part.cb-googlemaps .cb-googlemapscontainer > div {
  height: 100%!important;
}
.section--four .area .unit.unit--imageLeftRight .unit__content,
.section--four .area .unit.unit--mapLeftRight .unit__content,
.section--four .area .unit.unit--imageLeftRight .unit__foot,
.section--four .area .unit.unit--mapLeftRight .unit__foot {
  box-sizing: border-box;
  padding-left: 62.5%;
}
.section--four .area .unit.unit--imageLeftRight.unit.imageRightmapRight .unit__background,
.section--four .area .unit.unit--mapLeftRight.unit.imageRightmapRight .unit__background,
.section--four .area .unit.unit--imageLeftRight.unit.imageRight .unit__background,
.section--four .area .unit.unit--mapLeftRight.unit.imageRight .unit__background,
.section--four .area .unit.unit--imageLeftRight.unit.imageRightmapRight .part.cb-googlemaps,
.section--four .area .unit.unit--mapLeftRight.unit.imageRightmapRight .part.cb-googlemaps,
.section--four .area .unit.unit--imageLeftRight.unit.imageRight .part.cb-googlemaps,
.section--four .area .unit.unit--mapLeftRight.unit.imageRight .part.cb-googlemaps {
  right: unset;
  left: 58%;
}
.section--four .area .unit.unit--imageLeftRight.unit.imageRightmapRight .unit__content,
.section--four .area .unit.unit--mapLeftRight.unit.imageRightmapRight .unit__content,
.section--four .area .unit.unit--imageLeftRight.unit.imageRight .unit__content,
.section--four .area .unit.unit--mapLeftRight.unit.imageRight .unit__content,
.section--four .area .unit.unit--imageLeftRight.unit.imageRightmapRight .unit__foot,
.section--four .area .unit.unit--mapLeftRight.unit.imageRightmapRight .unit__foot,
.section--four .area .unit.unit--imageLeftRight.unit.imageRight .unit__foot,
.section--four .area .unit.unit--mapLeftRight.unit.imageRight .unit__foot {
  padding-left: 0;
  padding-right: calc(50% + 10px);
}
/*# sourceMappingURL=./screen-medium.css.map */